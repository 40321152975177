import { type ReactNode, forwardRef } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Select, { type SelectProps } from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';

export type FilledMultiSelectProps = SelectProps & {
  label?: string;
  valueText?: ReactNode;
};

export const FilledMultiSelect = forwardRef<
  HTMLDivElement,
  FilledMultiSelectProps
>(function FilledMultiSelect(selectProps, ref) {
  const { children, label, valueText, ...props } = selectProps;
  const theme = useTheme();

  return (
    <Select
      {...props}
      size={'small'}
      variant="filled"
      multiple
      displayEmpty
      renderValue={(selected: string) => {
        if (selected.length === 0) {
          return <span>{label}</span>;
        }

        return (
          <>
            <span>
              {label}
              {valueText ? ':' : ''}
            </span>
            {valueText && (
              <span suppressHydrationWarning css={{ fontWeight: 400 }}>
                {valueText}
              </span>
            )}
            {(!valueText || selected.length > 1) && (
              <span
                css={{
                  fontSize: '10px',
                  display: 'inline-block',
                  backgroundColor: theme.palette.link,
                  borderRadius: '50%',
                  minWidth: 16.8,
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                {valueText ? `+${selected.length - 1}` : selected.length}
              </span>
            )}
          </>
        );
      }}
      IconComponent={
        (selectProps.value as string[]).length > 0
          ? (props) => (
              <IconButton
                {...props}
                size="small"
                sx={{
                  '&.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.MuiSelect-icon.MuiSelect-iconFilled':
                    {
                      p: 0.25,
                      top: '1px',
                      right: '1px',
                      pointerEvents: 'unset',
                    },
                }}
                onClick={() => {
                  selectProps.onChange?.(
                    {
                      target: {
                        value: [],
                      },
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    } as any,
                    null
                  );
                }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )
          : undefined
      }
      MenuProps={{
        sx: (theme) => ({
          '& .MuiMenuItem-root.Mui-selected': {
            backgroundColor: `${theme.palette.highlight}99`,
          },
          '& .MuiMenuItem-root.Mui-selected:hover': {
            backgroundColor: `${theme.palette.highlight}cc`,
          },
        }),
      }}
      inputProps={{
        inputRef: ref,
        sx: {
          py: 0.5,
        },
      }}
      sx={{
        fontSize: '14px',
        lineHeight: '16.8px',
        fontWeight: 600,
        borderRadius: 1,
        '& .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input.MuiSelect-select':
          {
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            py: 0.5,
          },
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        ...((selectProps.value as string[]).length > 0
          ? {
              color: 'link',
              backgroundColor: 'highlight',
              '&:hover': {
                backgroundColor: 'highlight',
                filter: 'brightness(85%)',
              },
              '&.Mui-focused': {
                backgroundColor: 'highlight',
                filter: 'brightness(95%)',
              },
            }
          : {}),
      }}
    >
      {children}
    </Select>
  );
});
