import {
  type Theme,
  createTheme as createMuiTheme,
} from '@mui/material/styles';
import { Domine, Inter } from 'next/font/google';

const domine = Domine({
  subsets: ['latin'],
  weight: ['400', '700'],
});

const inter = Inter({
  subsets: ['latin'],
  display: 'swap',
});

const palette = {
  text: {
    primary: '#121247',
    secondary: 'rgba(18, 18, 71, 0.7)',
    disabled: 'rgba(18, 18, 71, 0.4)',
  },
  primary: {
    main: '#086EC5',
    dark: '#0D5E9B',
    light: '#06A5FF',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#486681',
    dark: '#344758',
    light: '#7D9CB3',
    contrastText: '#FFFFFF',
  },
  gray: {
    main: '#757575',
    dark: '#424242',
    light: '#BDBDBD',
    contrastText: '#FFFFFF',
  },
  action: {
    active: 'rgba(18, 18, 71, 0.56)',
    hover: 'rgba(18, 18, 71, 0.08)',
    selected: 'rgba(18, 18, 71, 0.16)',
    focus: 'rgba(18, 18, 71, 0.12)',
    disabled: 'rgba(18, 18, 71, 0.38)',
    disabledBackground: 'rgba(18, 18, 71, 0.12)',
  },
  error: {
    dark: '#B71C1C',
    contrastText: '#FFFFFF',
    main: '#D32F2F',
  },
  warning: {
    main: '#F57C00',
    contrastText: '#FFFFFF',
    dark: '#E65100',
  },
  alertColors: {
    success: {
      light: '#EDF7ED',
      main: '#388E3C',
      dark: '#1E4620',
    },
    info: {
      light: '#E5F6FD',
      main: '#0288D1',
      dark: '#014361',
    },
    warning: {
      light: '#FFF4E5',
      main: '#F57C00',
      dark: '#663C00',
    },
    error: {
      light: '#FDEDED',
      main: '#D32F2F',
      dark: '#5F2120',
    },
    grey: {
      light: '#7575751F',
      main: '#757575',
      dark: '#424242',
    },
  },
  decisionStatus: {
    APPROVED: '#388E3C',
    AWAITING_DECISION: '#F5BB25',
    BOOKED: '#424242',
    CONDITIONALLY_APPROVED: '#0288D1',
    DECLINED: '#D32F2F',
    DOCUMENTS_INCOMPLETE: '#612F72',
    DRAFT: '#BDBDBD',
    INCOMPLETE: '#F57C00',
    PENDING_BANK_CONNECTION: '#757575',
    WITHDRAWN: '#BF62C7',
  },
  info: {
    contrastText: '#FFFFFF',
    main: '#0288D1',
  },
  success: {
    main: '#388E3C',
    contrastText: '#FFFFFF',
  },
  common: {
    black: '#121247',
    white: '#FDFBF7',
  },
  background: {
    default: '#FDFBF7',
  },
  divider: '#E0E0E0',

  tables: {
    header: '#F5F5F5',
    borderColor: '#E0E0E0',
    hover: '#FAFAFA',
  },
};

/**
 *
 * New version of the theme, introducing the new design system made by Dan
 * This is still work in progress
 * @param dark
 * @returns
 */
export const createTheme3 = (): Theme => {
  return createMuiTheme({
    palette,
    typography: {
      h1: {
        ...domine.style,
        fontSize: '3.75rem',
        fontWeight: 400,
        lineHeight: '120%',
      },
      h2: {
        ...domine.style,
        fontSize: '3rem',
        fontWeight: 400,
        lineHeight: '116%',
      },
      h3: {
        ...domine.style,
        fontSize: '2.125rem',
        lineHeight: '48px',
      },
      h4: {
        ...domine.style,
        fontSize: '1.75rem',
        lineHeight: '40px',
      },
      h5: {
        ...inter.style,
      },
      h6: {
        ...inter.style,
      },
      body1: {
        ...inter.style,
      },
      body2: {
        ...inter.style,
      },
      subtitle1: {
        fontSize: '12px',
        color: palette.secondary.main,
        ...inter.style,
      },
      subtitle2: {
        ...inter.style,
      },
      overline: {
        ...inter.style,
      },
      caption: {
        ...inter.style,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: ({ theme }) => ({
            // '&.MuiButton-contained': {
            //   boxShadow: 1,
            // },
            '&.MuiButton-sizeLarge.MuiButton-containedPrimary': {
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.9375rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px /* 173.333% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.primary.contrastText,
              },
              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeLarge.MuiButton-containedInherit': {
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.9375rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px /* 173.333% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.text.primary,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeLarge.MuiButton-containedInherit (white)': {
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.9375rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px /* 173.333% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.common.white,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeLarge.MuiButton-containedSecondary': {
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.9375rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px /* 173.333% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.secondary.contrastText,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeLarge.MuiButton-containedError': {
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.9375rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px /* 173.333% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.error.contrastText,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeLarge.MuiButton-containedWarning': {
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.9375rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px /* 173.333% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.warning.contrastText,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeLarge.MuiButton-containedInfo': {
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.9375rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px /* 173.333% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.info.contrastText,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeLarge.MuiButton-containedSuccess': {
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.9375rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px /* 173.333% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.success.contrastText,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeMedium.MuiButton-containedPrimary': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px /* 171.429% */',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.primary.contrastText,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeMedium.MuiButton-containedInherit': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px /* 171.429% */',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.text.primary,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeMedium.MuiButton-containedInherit (white)': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px /* 171.429% */',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.common.white,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeMedium.MuiButton-containedSecondary': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px /* 171.429% */',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.secondary.contrastText,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeMedium.MuiButton-containedError': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px /* 171.429% */',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.error.contrastText,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeMedium.MuiButton-containedWarning': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px /* 171.429% */',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.warning.contrastText,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeMedium.MuiButton-containedInfo': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px /* 171.429% */',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.info.contrastText,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeMedium.MuiButton-containedSuccess': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px /* 171.429% */',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.success.contrastText,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeSmall.MuiButton-containedPrimary': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.8125rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px /* 169.231% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.primary.contrastText,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeSmall.MuiButton-containedInherit': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.8125rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px /* 169.231% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.text.primary,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeSmall.MuiButton-containedInherit (white)': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.8125rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px /* 169.231% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.common.white,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeSmall.MuiButton-containedSecondary': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.8125rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px /* 169.231% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.secondary.contrastText,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeSmall.MuiButton-containedError': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.8125rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px /* 169.231% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.error.contrastText,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeSmall.MuiButton-containedWarning': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.8125rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px /* 169.231% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.warning.contrastText,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeSmall.MuiButton-containedInfo': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.8125rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px /* 169.231% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.info.contrastText,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeSmall.MuiButton-containedSuccess': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.8125rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px /* 169.231% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.success.contrastText,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeLarge.MuiButton-outlinedPrimary': {
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.9375rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px /* 173.333% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              padding: '8px 22px',
              borderRadius: `${theme.shape.borderRadius}`,
              opacity: '1',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.primary.main,
                border: `1.5px solid ${theme.palette.primary.main}`,
                background: 'none',
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
                border: `1.5px solid ${theme.palette.action.disabledBackground}`,
                background: 'none',
              },
            },
            '&.MuiButton-sizeLarge.MuiButton-outlinedInherit': {
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.9375rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px /* 173.333% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              padding: '8px 22px',
              borderRadius: `${theme.shape.borderRadius}`,
              opacity: '1',

              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.text.primary,
                border: `1.5px solid ${theme.palette.text.primary}`,
                background: 'none',
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
                border: `1.5px solid ${theme.palette.action.disabledBackground}`,
                background: 'none',
              },
            },
            '&.MuiButton-sizeLarge.MuiButton-outlinedInherit (white)': {
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.9375rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px /* 173.333% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              padding: '8px 22px',
              borderRadius: `${theme.shape.borderRadius}`,
              opacity: '1',

              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.common.white,
                border: `1.5px solid ${theme.palette.common.white}`,
                background: 'none',
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
                border: `1.5px solid ${theme.palette.action.disabledBackground}`,
                background: 'none',
              },
            },
            '&.MuiButton-sizeLarge.MuiButton-outlinedSecondary': {
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.9375rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px /* 173.333% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              padding: '8px 22px',
              borderRadius: `${theme.shape.borderRadius}`,
              opacity: '1',

              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.secondary.main,
                border: `1.5px solid ${theme.palette.secondary.main}`,
                background: 'none',
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
                border: `1.5px solid ${theme.palette.action.disabledBackground}`,
                background: 'none',
              },
            },
            '&.MuiButton-sizeLarge.MuiButton-outlinedError': {
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.9375rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px /* 173.333% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              padding: '8px 22px',
              borderRadius: `${theme.shape.borderRadius}`,
              opacity: '1',

              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.error.main,
                border: `1.5px solid ${theme.palette.error.main}`,
                background: 'none',
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
                border: `1.5px solid ${theme.palette.action.disabledBackground}`,
                background: 'none',
              },
            },
            '&.MuiButton-sizeLarge.MuiButton-outlinedWarning': {
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.9375rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px /* 173.333% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              padding: '8px 22px',
              borderRadius: `${theme.shape.borderRadius}`,
              opacity: '1',

              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.warning.main,
                border: `1.5px solid ${theme.palette.warning.main}`,
                background: 'none',
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
                border: `1.5px solid ${theme.palette.action.disabledBackground}`,
                background: 'none',
              },
            },
            '&.MuiButton-sizeLarge.MuiButton-outlinedInfo': {
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.9375rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px /* 173.333% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              padding: '8px 22px',
              borderRadius: `${theme.shape.borderRadius}`,
              opacity: '1',

              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.info.main,
                border: `1.5px solid ${theme.palette.info}`,
                background: 'none',
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
                border: `1.5px solid ${theme.palette.action.disabledBackground}`,
                background: 'none',
              },
            },
            '&.MuiButton-sizeLarge.MuiButton-outlinedSuccess': {
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.9375rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px /* 173.333% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              padding: '8px 22px',
              borderRadius: `${theme.shape.borderRadius}`,
              opacity: '1',

              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.success.main,
                border: `1.5px solid ${theme.palette.success.main}`,
                background: 'none',
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
                border: `1.5px solid ${theme.palette.action.disabledBackground}`,
                background: 'none',
              },
            },
            '&.MuiButton-sizeMedium.MuiButton-outlinedPrimary': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px /* 171.429% */',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              padding: '6px 16px',
              borderRadius: `${theme.shape.borderRadius}`,
              opacity: '1',

              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.primary.main,
                border: `1.5px solid ${theme.palette.primary.main}`,
                background: 'none',
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
                border: `1.5px solid ${theme.palette.action.disabledBackground}`,
                background: 'none',
              },
            },
            '&.MuiButton-sizeMedium.MuiButton-outlinedInherit': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px /* 171.429% */',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              padding: '6px 16px',
              borderRadius: `${theme.shape.borderRadius}`,
              opacity: '1',

              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.text.primary,
                border: `1.5px solid ${theme.palette.text.primary}`,
                background: 'none',
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
                border: `1.5px solid ${theme.palette.action.disabledBackground}`,
                background: 'none',
              },
            },
            '&.MuiButton-sizeMedium.MuiButton-outlinedInherit (white)': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px /* 171.429% */',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              padding: '6px 16px',
              borderRadius: `${theme.shape.borderRadius}`,
              opacity: '1',

              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.common.white,
                border: `1.5px solid ${theme.palette.common.white}`,
                background: 'none',
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
                border: `1.5px solid ${theme.palette.action.disabledBackground}`,
                background: 'none',
              },
            },
            '&.MuiButton-sizeMedium.MuiButton-outlinedSecondary': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px /* 171.429% */',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              padding: '6px 16px',
              borderRadius: `${theme.shape.borderRadius}`,
              opacity: '1',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.secondary.main,
                border: `1.5px solid ${theme.palette.secondary.main}`,
                background: 'none',
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
                border: `1.5px solid ${theme.palette.action.disabledBackground}`,
                background: 'none',
              },
            },
            '&.MuiButton-sizeMedium.MuiButton-outlinedError': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px /* 171.429% */',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              padding: '6px 16px',
              borderRadius: `${theme.shape.borderRadius}`,
              opacity: '1',

              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.error.main,
                border: `1.5px solid ${theme.palette.error.main}`,
                background: 'none',
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
                border: `1.5px solid ${theme.palette.action.disabledBackground}`,
                background: 'none',
              },
            },
            '&.MuiButton-sizeMedium.MuiButton-outlinedWarning': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px /* 171.429% */',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              padding: '6px 16px',
              borderRadius: `${theme.shape.borderRadius}`,
              opacity: '1',

              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.warning.main,
                border: `1.5px solid ${theme.palette.warning.main}`,
                background: 'none',
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
                border: `1.5px solid ${theme.palette.action.disabledBackground}`,
                background: 'none',
              },
            },
            '&.MuiButton-sizeMedium.MuiButton-outlinedInfo': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px /* 171.429% */',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              padding: '6px 16px',
              borderRadius: `${theme.shape.borderRadius}`,
              opacity: '1',

              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.info.main,
                border: `1.5px solid ${theme.palette.info}`,
                background: 'none',
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
                border: `1.5px solid ${theme.palette.action.disabledBackground}`,
                background: 'none',
              },
            },
            '&.MuiButton-sizeMedium.MuiButton-outlinedSuccess': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px /* 171.429% */',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              padding: '6px 16px',
              borderRadius: `${theme.shape.borderRadius}`,
              opacity: '1',

              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.success.main,
                border: `1.5px solid ${theme.palette.success.main}`,
                background: 'none',
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
                border: `1.5px solid ${theme.palette.action.disabledBackground}`,
                background: 'none',
              },
            },
            '&.MuiButton-sizeSmall.MuiButton-outlinedPrimary': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.8125rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px /* 169.231% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              padding: '4px 10px',
              borderRadius: `${theme.shape.borderRadius}`,
              opacity: '1',

              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.primary,
                border: `1.5px solid ${theme.palette.primary.main}`,
                background: 'none',
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
                border: `1.5px solid ${theme.palette.action.disabledBackground}`,
                background: 'none',
              },
            },
            '&.MuiButton-sizeSmall.MuiButton-outlinedInherit': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.8125rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px /* 169.231% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              padding: '4px 10px',
              borderRadius: `${theme.shape.borderRadius}`,
              opacity: '1',

              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.text.primary,
                border: `1.5px solid ${theme.palette.text.primary}`,
                background: 'none',
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
                border: `1.5px solid ${theme.palette.action.disabledBackground}`,
                background: 'none',
              },
            },
            '&.MuiButton-sizeSmall.MuiButton-outlinedInherit (white)': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.8125rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px /* 169.231% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              padding: '4px 10px',
              borderRadius: `${theme.shape.borderRadius}`,
              opacity: '1',

              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.common.white,
                border: `1.5px solid ${theme.palette.common.white}`,
                background: 'none',
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
                border: `1.5px solid ${theme.palette.action.disabledBackground}`,
                background: 'none',
              },
            },
            '&.MuiButton-sizeSmall.MuiButton-outlinedSecondary': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.8125rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px /* 169.231% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              padding: '4px 10px',
              borderRadius: `${theme.shape.borderRadius}`,
              opacity: '1',

              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.secondary.main,
                border: `1.5px solid ${theme.palette.secondary.main}`,
                background: 'none',
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
                border: `1.5px solid ${theme.palette.action.disabledBackground}`,
                background: 'none',
              },
            },
            '&.MuiButton-sizeSmall.MuiButton-outlinedError': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.8125rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px /* 169.231% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              padding: '4px 10px',
              borderRadius: `${theme.shape.borderRadius}`,
              opacity: '1',

              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.error.main,
                border: `1.5px solid ${theme.palette.error.main}`,
                background: 'none',
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
                border: `1.5px solid ${theme.palette.action.disabledBackground}`,
                background: 'none',
              },
            },
            '&.MuiButton-sizeSmall.MuiButton-outlinedWarning': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.8125rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px /* 169.231% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              padding: '4px 10px',
              borderRadius: `${theme.shape.borderRadius}`,
              opacity: '1',

              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.warning.main,
                border: `1.5px solid ${theme.palette.warning.main}`,
                background: 'none',
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
                border: `1.5px solid ${theme.palette.action.disabledBackground}`,
                background: 'none',
              },
            },
            '&.MuiButton-sizeSmall.MuiButton-outlinedInfo': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.8125rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px /* 169.231% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              padding: '4px 10px',
              borderRadius: `${theme.shape.borderRadius}`,
              opacity: '1',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.info.main,
                border: `1.5px solid ${theme.palette.info}`,
                background: 'none',
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
                border: `1.5px solid ${theme.palette.action.disabledBackground}`,
                background: 'none',
              },
            },
            '&.MuiButton-sizeSmall.MuiButton-outlinedSuccess': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.8125rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px /* 169.231% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              padding: '4px 10px',
              borderRadius: `${theme.shape.borderRadius}`,
              opacity: '1',

              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.success.main,
                border: `1.5px solid ${theme.palette.success.main}`,
                background: 'none',
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
                border: `1.5px solid ${theme.palette.action.disabledBackground}`,
                background: 'none',
              },
            },
            '&.MuiButton-sizeMedium.MuiButton-textPrimary': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px /* 171.429% */',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              // '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: theme.palette.primary.main,
              // },

              '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
                color: theme.palette.primary.main,
              },
            },
            '&.MuiButton-sizeMedium.MuiButton-textInherit': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px /* 171.429% */',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.text.primary,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeMedium.MuiButton-textInherit (white)': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px /* 171.429% */',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.common.white,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeMedium.MuiButton-textSecondary': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px /* 171.429% */',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.secondary.main,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeMedium.MuiButton-textError': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px /* 171.429% */',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.error.main,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeMedium.MuiButton-textWarning': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px /* 171.429% */',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.warning.main,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeMedium.MuiButton-textInfo': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px /* 171.429% */',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.info.main,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeMedium.MuiButton-textSuccess': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px /* 171.429% */',
              letterSpacing: '0.4px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.success.main,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeLarge.MuiButton-textPrimary': {
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.9375rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px /* 173.333% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.primary.main,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeLarge.MuiButton-textInherit': {
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.9375rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px /* 173.333% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.text.primary,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeLarge.MuiButton-textInherit (white)': {
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.9375rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px /* 173.333% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.common.white,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeLarge.MuiButton-textSecondary': {
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.9375rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px /* 173.333% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.secondary.main,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeLarge.MuiButton-textError': {
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.9375rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px /* 173.333% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.error.main,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeLarge.MuiButton-textWarning': {
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.9375rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px /* 173.333% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.warning.main,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeLarge.MuiButton-textInfo': {
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.9375rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px /* 173.333% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.info.main,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeLarge.MuiButton-textSuccess': {
              fontFeatureSettings: "'liga' off, 'clig' off",
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.9375rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '26px /* 173.333% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.success.main,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeSmall.MuiButton-textPrimary': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.8125rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px /* 169.231% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.primary.main,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeSmall.MuiButton-textInherit': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.8125rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px /* 169.231% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.text.primary,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeSmall.MuiButton-textInherit (white)': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.8125rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px /* 169.231% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.common.white,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeSmall.MuiButton-textSecondary': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.8125rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px /* 169.231% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.secondary.main,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeSmall.MuiButton-textError': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.8125rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px /* 169.231% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.error.main,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeSmall.MuiButton-textWarning': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.8125rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px /* 169.231% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.warning.main,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeSmall.MuiButton-textInfo': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.8125rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px /* 169.231% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.info.main,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
            '&.MuiButton-sizeSmall.MuiButton-textSuccess': {
              fontFamily: 'Inter, Helvetica, Arial, sans-serif',
              fontSize: '0.8125rem',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '22px /* 169.231% */',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.success.main,
              },

              '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
                color: theme.palette.action.disabled,
              },
            },
          }),
        },
        defaultProps: {
          disableElevation: false,
          size: 'large',
        },
        variants: [
          {
            props: { variant: 'contained', color: 'primary', size: 'large' },
            style: { fontSize: '16px', padding: '8px 22px' },
          },
          {
            props: { variant: 'contained', color: 'primary', size: 'small' },
            style: { fontSize: '12px', padding: '6px 14px' },
          },
          {
            props: { variant: 'contained', color: 'secondary' },
            style: { fontSize: '16px', padding: '8px 22px' },
          },
          {
            props: { variant: 'outlined', color: 'primary' },
            style: {
              fontSize: '16px',
              padding: '8px 22px',
              color: palette.primary.main,
              border: `1.5px solid ${palette.primary.main}`,
            },
          },
          {
            props: { variant: 'outlined', color: 'secondary' },
            style: {
              fontSize: '16px',
              padding: '8px 22px',
              color: palette.secondary.main,
              border: `1.5px solid ${palette.secondary.main}`,
            },
          },
          {
            props: { variant: 'outlined', color: 'primary', size: 'small' },
            style: {
              fontSize: '14px',
              padding: '4px 11px',
              color: palette.primary.main,
              border: `1.5px solid ${palette.primary.main}`,
            },
          },
          {
            props: { variant: 'outlined', color: 'secondary', size: 'small' },
            style: {
              fontSize: '14px',
              padding: '4px 11px',
              color: palette.secondary.main,
              border: `1.5px solid ${palette.secondary.main}`,
            },
          },
        ],
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            fontFamily: inter.style.fontFamily,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: palette.action.hover,
            },
            color: palette.action.active,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: theme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          }),
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: palette.divider,
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: theme.shape.borderRadius,
            overflow: 'hidden',
            outline: '1px solid',
            outlineColor: theme.palette.divider,
            outlineOffset: '-1px',
          }),
        },
      },

      MuiTableHead: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderColor: theme.palette.divider,
            '.MuiTableRow-root': {
              backgroundColor: palette.tables.header,
              border: 'solid 1px',
              borderColor: palette.divider,
            },
          }),
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: () => ({}),
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderBottom: `solid 1px ${theme.palette.tables.borderColor}`,
          }),
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            backgroundColor: palette.common.white,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            backgroundColor: palette.common.white,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            backgroundColor: palette.common.white,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: '#fff',
          },
        },
      },
    },
  });
};
