import { type ReactElement } from 'react';

import Typography, { type TypographyProps } from '@mui/material/Typography';
import useTranslation from 'next-translate/useTranslation';

export type NumericValueProps = TypographyProps & {
  value: number | null | undefined;
  prefix?: string;
  suffix?: string;
  decimalPlaces?: number;
  component?: React.ElementType;
  currency?: 'CAD' | 'USD';
};

const isANumber = (value: number | null | undefined) =>
  value !== undefined &&
  value !== null &&
  !Number.isNaN(value) &&
  Number.isFinite(value);

const getNumberFormat = ({
  value,
  decimalPlaces,
  lang,
  currency = 'CAD',
}: NumericValueProps & { lang: string }): Intl.NumberFormat => {
  try {
    return new Intl.NumberFormat([lang], {
      maximumFractionDigits: decimalPlaces,
      minimumFractionDigits: decimalPlaces,
      signDisplay: (value ?? 0) < 0 ? 'exceptZero' : 'never',
      currency,
    });
  } catch (e) {
    return new Intl.NumberFormat([lang], {
      maximumFractionDigits: decimalPlaces,
      minimumFractionDigits: decimalPlaces,
    });
  }
};

export const formatNumber = ({
  value,
  decimalPlaces = 2,
  lang,
  currency = 'CAD',
}: NumericValueProps & { lang: string }): string =>
  isANumber(value)
    ? getNumberFormat({
        value,
        decimalPlaces,
        lang,
        currency,
      }).format(value ?? 0)
    : '-';

export const useFormatNumber = ({
  decimalPlaces = 2,
}: Omit<NumericValueProps, 'value'>): ((
  value: number | null | undefined,
  currency?: 'CAD' | 'USD'
) => string) => {
  const { lang } = useTranslation();
  return (value, currency = 'CAD') =>
    formatNumber({
      value,
      decimalPlaces,
      lang,
      currency,
    });
};

export const NumericValue = ({
  value,
  prefix,
  suffix,
  decimalPlaces = 2,
  ...props
}: NumericValueProps): ReactElement => {
  const { lang } = useTranslation();
  const formattedValue = isANumber(value)
    ? getNumberFormat({
        value,
        decimalPlaces,
        lang,
      }).format(value ?? 0)
    : '-';
  return (
    <Typography {...props}>
      {prefix}
      {formattedValue}
      {suffix}
    </Typography>
  );
};
