import React, { type ReactElement } from 'react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { type SxProps } from '@mui/material/styles';

interface IAddRemoveButton {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  text: string;
  remove?: boolean;
  sx?: SxProps;
}

export const AddOrRemoveButton = ({
  onClick,
  text,
  remove,
  sx,
}: IAddRemoveButton): ReactElement => {
  return (
    <Button
      onClick={onClick}
      variant="text"
      sx={{
        width: 'fit-content',
        alignSelf: 'flex-end',
        ...sx,
      }}
      startIcon={
        remove ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />
      }
    >
      <Typography sx={{ display: { xs: 'none', md: 'block' } }}>
        {text}
      </Typography>
    </Button>
  );
};
