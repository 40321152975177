import { type ReactNode, forwardRef } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Select, { type SelectProps } from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';

export type MultiSelectProps = SelectProps & {
  label?: string;
  valueText?: ReactNode;
};

export const MultiSelect = forwardRef<HTMLDivElement, MultiSelectProps>(
  function MultiSelect(selectProps, ref) {
    const { children, label, valueText, ...props } = selectProps;
    const theme = useTheme();

    return (
      <Select
        {...props}
        size="small"
        multiple
        displayEmpty
        renderValue={(selected: string) => {
          if (selected.length === 0) {
            return <span>{label}</span>;
          }

          return (
            <>
              <span>
                {label}
                {valueText ? ': ' : ''}
              </span>
              {valueText && (
                <span suppressHydrationWarning css={{ fontWeight: 400 }}>
                  {valueText}
                </span>
              )}
              {(!valueText || selected.length > 1) && (
                <span
                  css={{
                    fontSize: '10px',
                    display: 'inline-block',
                    marginLeft: theme.spacing(0.5),
                    backgroundColor: theme.palette.action.hover,
                    borderRadius: '50%',
                    minWidth: 16.8,
                    lineHeight: '16px',
                    color: theme.palette.text.primary,
                    textAlign: 'center',
                    position: 'relative',
                    top: '-2px',
                  }}
                >
                  {valueText ? `+${selected.length - 1}` : selected.length}
                </span>
              )}
            </>
          );
        }}
        IconComponent={
          (selectProps.value as string[]).length > 0
            ? (props) => (
                <IconButton
                  {...props}
                  size="small"
                  sx={{
                    '&.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.MuiSelect-icon.MuiSelect-icon':
                      {
                        p: 0.25,
                        top: '8px',
                        right: '4px',
                        pointerEvents: 'unset',
                      },
                  }}
                  onClick={() => {
                    selectProps.onChange?.(
                      {
                        target: {
                          value: [],
                        },
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      } as any,
                      null
                    );
                  }}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              )
            : undefined
        }
        inputProps={{
          inputRef: ref,
        }}
      >
        {children}
      </Select>
    );
  }
);
