import React, { type ReactElement, type ReactNode } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import useTranslation from 'next-translate/useTranslation';
import getConfig from 'next/config';
import Image from 'next/image';

import { LanguageSelector } from '../LanguageSelector';
import { type IPage } from '../TopBar';

interface IProps {
  pages: IPage[];
  additionalItems?: ReactNode;
  onMenuOpen: (e: React.MouseEvent<HTMLElement>) => void;
  onMenuClose: () => void;
  anchorElNav: null | HTMLElement;
  logoOnly?: boolean;
  noLink?: boolean;
  linkProps?: {
    href?: string;
    rel?: string;
    target?: string;
  };
  logoSrc?: string;
  showLanguageSelector?: boolean;
}

export const TopBarMobile = ({
  noLink,
  linkProps,
  logoSrc,
  showLanguageSelector = false,
}: IProps): ReactElement => {
  const { t } = useTranslation('common');
  const { publicRuntimeConfig } = getConfig();

  const logo = (
    <Image
      priority
      alt={t('appTitle')}
      src={
        logoSrc ?? `${publicRuntimeConfig.public_prefix}/images/main-logo-2.svg`
      }
      height={32}
      width={150}
      className="logo"
    />
  );

  const logoWithLink = (
    <Link
      {...linkProps}
      href={linkProps?.href ? linkProps.href : '/'}
      underline="none"
    >
      {logo}
    </Link>
  );

  const resolvedLogo = noLink ? logo : logoWithLink;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: showLanguageSelector ? 'space-between' : 'center',
        alignItems: 'center',
        width: '100%',
        py: 2,
      }}
    >
      {resolvedLogo}
      {showLanguageSelector && <LanguageSelector />}
    </Box>
  );
};
