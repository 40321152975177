import React, { type ReactElement } from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';

import { CircularProgressWithLabel } from './CircularProgressWithLabel';
import { type FileIdWithProgress, UploadState } from './types';
import { shortenFileName } from './utils';

const MultiplesDocumentUploadFilenameBox = ({
  fileName,
  fileId,
  uploadState,
  onFileRemoved,
  progress,
}: {
  fileName: string;
  fileId: string;
  uploadState?: UploadState;
  onUploadFile?: () => Promise<void> | void;
  onFileRemoved?: (fileId: string) => void;
  disabled?: boolean;
  progress?: FileIdWithProgress | null;
}): ReactElement => {
  const { t } = useTranslation('common');
  const theme = useTheme();

  const ICON = {
    FILE_SELECTED: (
      <IconButton
        onClick={() => {
          if (onFileRemoved) onFileRemoved(fileId);
        }}
      >
        <DeleteOutlinedIcon
          sx={{
            color: theme.palette.action.active,
          }}
        />
      </IconButton>
    ),
    IN_PROGRESS: <CircularProgressWithLabel value={progress?.[fileId] ?? 0} />,
    SUCCESS: <CheckCircleIcon htmlColor={theme.palette.success.main} />,
    INFECTED: <CoronavirusIcon htmlColor={theme.palette.error.main} />,
    ERROR: <ErrorIcon htmlColor={theme.palette.error.main} />,
  } as Record<UploadState, React.ReactNode>;

  return (
    <Grid container>
      <Grid
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={1}
        size={12}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <AttachFileIcon />
          <Typography variant="body2" textAlign="left" noWrap>
            {fileName
              ? `${shortenFileName(fileName, 35)}`
              : t('uploadFileNotUploaded')}
          </Typography>
        </Box>
        {ICON[uploadState ?? UploadState.Initial]}
      </Grid>
    </Grid>
  );
};

export default MultiplesDocumentUploadFilenameBox;
