import { useCallback, useEffect, useState } from 'react';

import debounce from 'lodash/debounce';

import { SupportedDevices } from './supportedDevices';

export const useDeviceDetection = (): string => {
  const [device, setDevice] = useState<SupportedDevices>(
    SupportedDevices.Desktop
  ); // Default to Desktop for SSR

  const handleDeviceDetection = useCallback(() => {
    if (typeof navigator === 'undefined') return; // Check for SSR environments
    const userAgent = navigator.userAgent.toLowerCase();

    const isMobile =
      /iphone|ipod|android.*mobile|windows phone|blackberry/g.test(userAgent);
    const isTablet = /ipad|(tablet(?!.*mobile))|playbook|silk/g.test(userAgent);

    if (isMobile) {
      setDevice(SupportedDevices.Mobile);
    } else if (isTablet) {
      setDevice(SupportedDevices.Tablet);
    } else {
      setDevice(SupportedDevices.Desktop);
    }
  }, []);

  useEffect(() => {
    handleDeviceDetection();
    const debouncedHandleDeviceDetection = debounce(handleDeviceDetection, 150);

    window.addEventListener('resize', debouncedHandleDeviceDetection);
    return () => {
      window.removeEventListener('resize', debouncedHandleDeviceDetection);
      debouncedHandleDeviceDetection.cancel(); // Cancel the debounced function on cleanup
    };
  }, [handleDeviceDetection]);

  return device;
};

export default useDeviceDetection;
