import {
  type MutableRefObject,
  forwardRef,
  useCallback,
  useEffect,
} from 'react';

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Button from '@mui/material/Button';
import Grid2 from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { ErrorCode, useDropzone } from 'react-dropzone';

import { useNotification } from '../ErrorContext';
import { AWS_MAX_FILE_SIZE } from '../utils/constants';
import DocumentUploadBox from './DocumentUploadBox';
import { type IDocumentUpload } from './types';

export const DocumentUploadDropzoneV2 = forwardRef<
  HTMLInputElement,
  IDocumentUpload
>(function DocumentUploadDropzoneV2(
  { onFileSelected, maxFiles = 1, accept, sx, maxFileSize = AWS_MAX_FILE_SIZE },
  ref
) {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const { setErrorNotification } = useNotification();
  const onDrop = useCallback(
    async (acceptedFiles: Array<File>) => {
      if (acceptedFiles && acceptedFiles.length > 0 && onFileSelected) {
        await onFileSelected(acceptedFiles);
      }
    },
    [onFileSelected]
  );
  const { getRootProps, getInputProps, inputRef } = useDropzone({
    onDrop,
    onDropRejected(err) {
      if (
        err.some((errorItem) =>
          errorItem.errors.some((item) => item.code === ErrorCode.FileTooLarge)
        )
      ) {
        setErrorNotification(
          t('fileToLargeErrorMsg', {
            MAX_FILE_SIZE_LIMIT: AWS_MAX_FILE_SIZE / 1000000,
          })
        );
      } else {
        setErrorNotification(
          err?.[0].errors[0].message ?? t('errorInUploadingFile')
        );
      }
    },
    accept: accept ?? {
      'image/png': ['.png'],
      'image/jpg': ['.jpg', '.jpeg'],
      'image/heic': ['.heic'],
      'application/pdf': ['.pdf'],
      'application/json': ['.json'],
      'application/msword': ['.doc', '.docx'],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
      'text/csv': ['.csv'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
      'application/xml': ['.xml'],
    },
    maxFiles: maxFiles,
    maxSize: maxFileSize,
    multiple: maxFiles > 1,
  });

  useEffect(() => {
    if (ref && inputRef.current) {
      (ref as MutableRefObject<HTMLInputElement>).current = inputRef.current;
    }
  }, [inputRef, ref]);

  return (
    <DocumentUploadBox sx={{ ...sx, pt: 0 }}>
      <Grid2
        {...getRootProps()}
        sx={{
          gap: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          borderRadius: theme.spacing(1),
          outline: '2px dashed',
          outlineColor: `divider`,
          outlineOffset: '-2px',
          cursor: 'pointer',
          p: 2,
        }}
      >
        <input {...getInputProps()} />
        <CloudUploadOutlinedIcon
          data-test-id="documentUploadDropzoneV2"
          sx={{
            fontSize: '35px',
            color: 'gray.main',
          }}
        />
        <Typography>{t('dragFilesToUpload')}</Typography>
        <Typography variant={'subtitle1'} color="secondary">
          {t('or')}
        </Typography>
        <Button variant="text" href="#" onClick={(e) => e.preventDefault()}>
          {t('browsFiles')}
        </Button>
        <Typography variant={'subtitle1'} color="secondary">
          <Trans
            i18nKey="common:maxFileSize"
            values={{ size: AWS_MAX_FILE_SIZE / 1000000 }}
            components={{
              strong: <strong />,
            }}
          />
        </Typography>
        <Typography variant={'subtitle1'} color="secondary">
          <Trans
            i18nKey="common:supportedFileTypes"
            components={{
              strong: <strong />,
            }}
          />
        </Typography>
      </Grid2>
    </DocumentUploadBox>
  );
});
