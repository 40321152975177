import React, { type ReactElement, useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';

import { TopBarDesktop, TopBarMobile } from '@credit-app/shared-ui';

interface IPage {
  name: string;
  href: string;
}

interface IProps {
  logoOnly?: boolean;
  pages?: IPage[];
  showLanguageSelector?: boolean;
}

const TopBar = ({
  pages,
  showLanguageSelector = false,
}: IProps): ReactElement => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const top_bar_logo_link = 'https://creditapp.ca/consumers';
  return (
    <AppBar
      position="static"
      color="transparent"
      sx={{
        boxShadow: 0,
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          // Need to specify both to override existing media queries
          [theme.breakpoints.up('md')]: { minHeight: 48 },
          [theme.breakpoints.down('md')]: { minHeight: 48 },
        }}
      >
        <Box sx={{ display: { xs: 'none', md: 'flex' }, width: '100%' }}>
          <TopBarDesktop
            pages={pages ?? []}
            onNavItemClick={handleCloseNavMenu}
            linkProps={{
              href: top_bar_logo_link,
              rel: 'noopener',
              target: '_blank',
            }}
            logoSrc={theme.logo}
            showLanguageSelector={showLanguageSelector}
          />
        </Box>
        <Box sx={{ display: { xs: 'inherit', md: 'none' }, width: '100%' }}>
          <TopBarMobile
            pages={pages ?? []}
            onMenuOpen={handleOpenNavMenu}
            onMenuClose={handleCloseNavMenu}
            anchorElNav={anchorElNav}
            linkProps={{
              href: top_bar_logo_link,
              rel: 'noopener',
              target: '_blank',
            }}
            logoSrc={theme.logo}
            showLanguageSelector={showLanguageSelector}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
