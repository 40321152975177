import { type ReactNode, forwardRef } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Select, { type SelectProps } from '@mui/material/Select';

export type FilledSelectProps = SelectProps & {
  label?: string;
  hasValue?: boolean;
  valueText?: ReactNode;
};

export const FilledSelect = forwardRef<HTMLDivElement, FilledSelectProps>(
  function FilledSelect(selectProps, ref) {
    const { children, label, hasValue, valueText, ...props } = selectProps;

    return (
      <Select
        {...props}
        size={'small'}
        variant="filled"
        displayEmpty
        renderValue={() => {
          return (
            <>
              <span>{`${label}${valueText ? ':' : ''}`}</span>
              {valueText && (
                <span suppressHydrationWarning css={{ fontWeight: 400 }}>
                  {valueText}
                </span>
              )}
            </>
          );
        }}
        IconComponent={
          selectProps.value || hasValue
            ? (props) => (
                <IconButton
                  {...props}
                  size="small"
                  sx={{
                    '&.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.MuiSelect-icon.MuiSelect-iconFilled':
                      {
                        p: 0.25,
                        top: '1px',
                        right: '1px',
                        pointerEvents: 'unset',
                      },
                  }}
                  onClick={() => {
                    selectProps.onChange?.(
                      {
                        target: {
                          value: null,
                        },
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      } as any,
                      null
                    );
                  }}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              )
            : undefined
        }
        MenuProps={{
          sx: (theme) => ({
            '& .MuiMenuItem-root.Mui-selected': {
              backgroundColor: `${theme.palette.highlight}99`,
            },
            '& .MuiMenuItem-root.Mui-selected:hover': {
              backgroundColor: `${theme.palette.highlight}cc`,
            },
          }),
        }}
        inputProps={{
          inputRef: ref,
          sx: {
            py: 0.5,
          },
        }}
        sx={{
          fontSize: '14px',
          lineHeight: '16.8px',
          fontWeight: 600,
          borderRadius: 1,
          '& .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input.MuiInputBase-input':
            {
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              py: 0.5,
            },
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
          ...(selectProps.value || hasValue
            ? {
                color: 'link',
                backgroundColor: 'highlight',
                '&:hover': {
                  backgroundColor: 'highlight',
                  filter: 'brightness(85%)',
                },
                '&.Mui-focused': {
                  backgroundColor: 'highlight',
                  filter: 'brightness(95%)',
                },
              }
            : {}),
        }}
      >
        {children}
      </Select>
    );
  }
);
