import { type ReactElement, type ReactNode, useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container, { type ContainerProps } from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { grey } from '@mui/material/colors';
import { type SxProps, useTheme } from '@mui/material/styles';

import { TopBarDesktop } from '../TopBarDesktop';
import { TopBarMobile } from '../TopBarMobile';

export interface IPage {
  name: string;
  href: string;
  match?: RegExp;
}

export type TopBarProps = {
  usePages: () => IPage[];
  additionalItems?: ReactNode;
  logoOnly?: boolean;
  children?: ReactNode;
  maxWidth?: ContainerProps['maxWidth'];
  sx?: SxProps;
  noLink?: boolean;
  logoSrc?: string;
};

export const TopBar = ({
  logoOnly,
  usePages,
  additionalItems,
  children,
  maxWidth,
  sx,
  noLink = false,
  logoSrc,
}: TopBarProps): ReactElement => {
  const theme = useTheme();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const pages = usePages();
  return (
    <>
      <AppBar
        position="fixed"
        color="transparent"
        sx={{
          boxShadow: 0,
          borderBottom: `1px solid ${grey[400]}`,
          backgroundColor: 'background.paper',
          ...sx,
        }}
      >
        <Container maxWidth={maxWidth ?? 'xl'}>
          <Toolbar
            disableGutters
            sx={{
              // Need to specify both to override existing media queries
              [theme.breakpoints.up('md')]: { minHeight: 48 },
              [theme.breakpoints.down('md')]: { minHeight: 48 },
            }}
          >
            <Box sx={{ display: { xs: 'none', md: 'inherit' }, width: '100%' }}>
              <TopBarDesktop
                pages={pages}
                additionalItems={additionalItems}
                onNavItemClick={handleCloseNavMenu}
                noLink={noLink}
                logoSrc={logoSrc}
              />
            </Box>
            <Box sx={{ display: { xs: 'inherit', md: 'none' }, width: '100%' }}>
              <TopBarMobile
                pages={pages}
                additionalItems={additionalItems}
                onMenuOpen={handleOpenNavMenu}
                onMenuClose={handleCloseNavMenu}
                anchorElNav={anchorElNav}
                noLink={noLink}
                logoSrc={logoSrc}
              />
            </Box>
            {logoOnly && <Box sx={{ flexGrow: 1 }} />}
            {children}
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar
        sx={{
          // Need to specify both to override existing media queries
          [theme.breakpoints.up('md')]: { minHeight: 48 },
          [theme.breakpoints.down('md')]: { minHeight: 48 },
        }}
      />
    </>
  );
};
