import React, { type ReactElement } from 'react';

import Box from '@mui/material/Box';
import { type SxProps, useTheme } from '@mui/material/styles';

interface IDocumentUploadBox {
  children: React.ReactNode;
  sx?: SxProps;
}

const DocumentUploadBox = ({
  children,
  sx,
}: IDocumentUploadBox): ReactElement => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'auto',
        width: '100%',
        paddingTop: theme.spacing(2),
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default DocumentUploadBox;
