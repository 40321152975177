import { type ReactElement } from 'react';

import Typography, { type TypographyProps } from '@mui/material/Typography';
import { DateTime } from 'luxon';

export type YearMonthValueProps = TypographyProps & {
  value: DateTime | Date | string | number | null | undefined;
  prefix?: string;
  suffix?: string;
};

export const getYearMonthString = ({
  value,
}: {
  value: DateTime | Date | string | number | null | undefined;
}): string => {
  if (!value) {
    return '';
  }
  let date: DateTime | null = null;
  if (typeof value === 'string') {
    date = DateTime.fromISO(value);
  } else if (typeof value === 'number') {
    date = DateTime.fromMillis(value);
  } else if (value instanceof Date) {
    date = DateTime.fromJSDate(value);
  } else if (value instanceof DateTime) {
    date = value;
  }
  if (!date) {
    return '';
  }
  return date.toFormat('LLL ‘yy');
};

export const YearMonthValue = ({
  value,
  prefix,
  suffix,
  ...rest
}: YearMonthValueProps): ReactElement => (
  <Typography {...rest}>
    {prefix && `${prefix} `}
    {getYearMonthString({ value })}
    {suffix && ` ${suffix}`}
  </Typography>
);
