import { type ReactElement } from 'react';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

import DocumentUploadBox from './DocumentUploadBox';
import { type UploadState } from './types';

export const DocumentUploadInfected = ({
  errorMessage,
}: {
  fileName: string | undefined;
  errorMessage: string;
  uploadState: UploadState;
}): ReactElement => {
  return (
    <DocumentUploadBox>
      <Stack direction="column" sx={{ width: '100%' }}>
        <Alert severity="error">
          <AlertTitle>{errorMessage}</AlertTitle>
        </Alert>
      </Stack>
    </DocumentUploadBox>
  );
};
