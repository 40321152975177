import { forwardRef } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

import { TextInput, type TextInputProps } from '../TextInput';

export const ClearableTextInput = forwardRef<HTMLDivElement, TextInputProps>(
  function ClearableTextInput(textProps, ref) {
    return (
      <TextInput
        {...textProps}
        inputRef={ref}
        InputProps={{
          endAdornment: textProps.value ? (
            <IconButton
              size="small"
              sx={{
                p: 0.5,
              }}
              onClick={() => {
                textProps.onChange?.({
                  target: {
                    value: '',
                  },
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } as any);
              }}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ) : (
            textProps.InputProps?.endAdornment
          ),
        }}
      />
    );
  }
);
