import React, { type ReactElement } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.gray.main,
  marginBottom: '.2rem',
  fontSize: '14px',
}));

interface ISearchInput {
  label?: string;
  handleChange?: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
}

export const SearchInput = ({
  label = 'Default label',
  handleChange,
}: ISearchInput): ReactElement => {
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Label variant="body1">{label}</Label>
      <Paper
        component="form"
        sx={{
          height: '2rem',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          borderRadius: '20px',
          border: '1px solid gray',
          boxShadow: 'none',
        }}
      >
        <InputBase
          sx={{ ml: 2, flex: 1 }}
          placeholder="Type here"
          inputProps={{ 'aria-label': 'Type here' }}
          onChange={handleChange}
        />
        <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
    </Box>
  );
};
