import { type ReactNode, forwardRef, useState } from 'react';

import useTranslation from 'next-translate/useTranslation';

import { SelectV3, type SelectV3Props } from '../Select/SelectV3';
import { AmountRangeMenu } from './AmountRangeMenu';

export type AmountRangeSelectProps = SelectV3Props & {
  label: string;
  from: string;
  setFrom: (value: string) => void;
  to: string;
  setTo: (value: string) => void;
};

const formatMoney = (value: string) => {
  return Number(value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const AmountRangeSelect = forwardRef<
  HTMLDivElement,
  AmountRangeSelectProps
>(function AmountRangeSelect(
  { label, from, setFrom, to, setTo, ...rest },
  ref
): ReactNode {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  const getValueText = () => {
    if (from && to) {
      return `${formatMoney(from)} - ${formatMoney(to)}`;
    }
    if (from) {
      return `${t('from')} ${formatMoney(from)}`;
    }
    if (to) {
      return `${t('to')} ${formatMoney(to)}`;
    }
    return '';
  };
  return (
    <SelectV3
      {...rest}
      ref={ref}
      label={label}
      hasValue={Boolean(from || to)}
      valueText={getValueText()}
      onOpen={() => setOpen(true)}
      open={open}
      onClose={() => setOpen(false)}
      onChange={() => {
        setFrom('');
        setTo('');
      }}
    >
      <AmountRangeMenu
        from={from}
        setFrom={setFrom}
        to={to}
        setTo={setTo}
        onClose={() => setOpen(false)}
      />
    </SelectV3>
  );
});
