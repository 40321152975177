module.exports = {
  locales: ['en', 'fr'],
  defaultLocale: 'en',
  logBuild: false,
  pages: {
    '*': ['common'],
    'rgx:^/about': ['about', 'common', 'proofIncome'],
    'rgx:/auth': ['auth'],
    'rgx:sin': ['SINSubmission'],
    'rgx:proof-of-income': ['proofIncome'],
    'rgx:proof-of-insurance': ['proofOfInsurance'],
    'rgx:pre-auth-debit': ['preAuthDebit'],
    'rgx:proof-of-registration': ['proofOfRegistration'],
    'rgx:select-bank': ['proofIncome'],
    'rgx:banking': ['proofIncome'],
    'rgx:idv': ['idv'],
    'rgx:select-bank-account': ['proofIncome'],
    'rgx:bank-account-details': ['bankAccountDetails', 'proofIncome'],
    'rgx:banking/success': ['bankAccountDetails', 'proofIncome'],
    'rgx:^/terms-and-conditions': ['termsAndConditions'],
    'rgx:^/.*/funding2?/.*': ['otherDocument', 'overviewFundingPage'],
    'rgx:^/.*/funding/welcome': ['fundingWelcomePage'],
    'rgx:^/.*/funding/overview': ['overviewFundingPage'],
    'rgx:^/.*/funding2?/references': [
      'overviewFundingPage',
      'overviewFundingPage',
    ],
    'rgx:^/.*/funding2?/proof-of-identity': [
      'personalIdentification',
      'overviewFundingPage',
    ],
    'rgx:^/.*/funding2?/drivers-license': [
      'driversLicense',
      'overviewFundingPage',
      'personalIdentification',
    ],
    'rgx:^/.*/funding2?/proof-of-insurance': [
      'proofOfInsurance',
      'overviewFundingPage',
    ],
    'rgx:^/.*/funding2?/vehicle-registration': [
      'proofOfRegistration',
      'overviewFundingPage',
    ],
    'rgx:^/.*/funding2?/pre-authorized-debit': [
      'preAuthDebit',
      'overviewFundingPage',
    ],
    'rgx:welcome': [
      'welcome',
      'proofIncome',
      'termsAndConditions',
      'privacy',
      'aboutUs',
      'welcomeEq',
    ],
    'rgx:home': ['home', 'proofIncome', 'homeEq', 'homeACC'],
    'rgx:personal-identification': ['personalIdentification'],
    'rgx:references': ['references'],
    'rgx:start': [
      'common',
      'welcome',
      'checkPersonalDisclosure',
      'proofIncome',
      'termsAndConditions',
      'privacy',
      'aboutUs',
      'welcomeEq',
      'welcomeACC',
    ],
    'rgx:other-document': ['otherDocument'],
    'rgx:income': ['income', 'otherDocument'],
  },
};
