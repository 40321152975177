import { forwardRef } from 'react';

import { type InputProps } from '@mui/material/Input';
import TextField, { type TextFieldProps } from '@mui/material/TextField';

export type TextInputProps = TextFieldProps & {
  readOnly?: boolean;
  disableUnderline?: boolean;
};

export const TextInput = forwardRef<HTMLDivElement, TextInputProps>(
  function TextInput(textProps, ref) {
    const {
      value,
      fullWidth,
      sx,
      type = 'text',
      readOnly,
      disableUnderline,
      multiline,
      onBlur,
      onChange,
      ...props
    } = textProps;

    return (
      <TextField
        variant="standard"
        type={type}
        InputLabelProps={{ shrink: true }}
        value={value || ''}
        disabled={readOnly}
        onBlur={(e) => {
          if (onChange) {
            e.target.value = e.target.value.trim();
            onChange(e);
          }
          onBlur?.(e);
        }}
        onChange={onChange}
        inputProps={{
          ...props.inputProps,
          onKeyPress: (e) => {
            if (e.key === 'Enter' && !multiline) {
              e.currentTarget.blur();
            }
            props.inputProps?.onKeyPress?.(e);
          },
        }}
        {...props}
        InputProps={
          {
            ...props.InputProps,
            ...(disableUnderline ? { disableUnderline: true } : {}),
            inputRef: ref,
          } as InputProps
        }
        multiline={multiline}
        fullWidth={fullWidth}
        sx={{
          width: fullWidth ? undefined : '15rem',
          ...(readOnly
            ? {
                '& label.Mui-disabled': {
                  color: 'inherit',
                },
                '& .MuiInputBase-root.Mui-disabled': {
                  color: 'inherit',
                  '&:before': {
                    borderBottom: 'none',
                  },
                },
                '& input.Mui-disabled': {
                  WebkitTextFillColor: 'inherit',
                },
              }
            : {}),
          ...sx,
        }}
      />
    );
  }
);
