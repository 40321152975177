import { type ReactElement } from 'react';

import Stack from '@mui/material/Stack';
import Typography, { type TypographyProps } from '@mui/material/Typography';
import { type SxProps, type Theme } from '@mui/material/styles';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit.js';
import useTranslation from 'next-translate/useTranslation';

export type MoneyValueProps = TypographyProps & {
  currency?: 'CAD' | 'USD';
  value: number | null | undefined;
  originalValue?: number | null | undefined;
  prefix?: string;
  suffix?: string;
  decimalPlaces?: number;
  showPlusSign?: boolean;
  textAlign?: TypographyProps['textAlign'];
  component?: React.ElementType;
  allocation?: {
    amount?: number | null;
    percent?: number | null;
  };
};

const isANumber = (value: number | null | undefined) =>
  value !== undefined &&
  value !== null &&
  !Number.isNaN(value) &&
  Number.isFinite(value);

const getNumberFormat = ({
  currency,
  value,
  decimalPlaces,
  showPlusSign,
  lang,
}: MoneyValueProps & { lang: string }): Intl.NumberFormat => {
  try {
    return new Intl.NumberFormat([lang], {
      currency,
      currencyDisplay: 'narrowSymbol',
      maximumFractionDigits: decimalPlaces,
      minimumFractionDigits: decimalPlaces,
      style: 'currency',
      signDisplay: showPlusSign
        ? 'always'
        : (value ?? 0) < 0
          ? 'exceptZero'
          : 'never',
    });
  } catch (e) {
    return new Intl.NumberFormat([lang], {
      currency,
      currencyDisplay: 'symbol',
      maximumFractionDigits: decimalPlaces,
      minimumFractionDigits: decimalPlaces,
      style: 'currency',
      signDisplay: showPlusSign
        ? 'always'
        : (value ?? 0) < 0
          ? 'exceptZero'
          : 'never',
    });
  }
};

export const formatCurrency = ({
  currency = 'CAD',
  value,
  decimalPlaces = 2,
  showPlusSign,
  lang,
}: MoneyValueProps & { lang: string }): string =>
  isANumber(value)
    ? getNumberFormat({
        currency,
        value,
        decimalPlaces,
        showPlusSign,
        lang,
      }).format(value ?? 0)
    : '-';

export const useFormatCurrency = ({
  decimalPlaces = 2,
  showPlusSign,
}: Omit<MoneyValueProps, 'value' | 'currency'>): ((
  value: number | null | undefined,
  currency?: 'CAD' | 'USD'
) => string) => {
  const { lang } = useTranslation();
  return (value, currency = 'CAD') =>
    formatCurrency({
      currency,
      value,
      decimalPlaces,
      showPlusSign,
      lang,
    });
};

export const MoneyValue = ({
  currency = 'CAD',
  value,
  prefix,
  suffix,
  decimalPlaces = 2,
  showPlusSign,
  textAlign = 'right',
  ...props
}: MoneyValueProps): ReactElement => {
  const { lang } = useTranslation('financialSnapshot');
  const formattedValue = formatCurrency({
    currency,
    value,
    decimalPlaces,
    showPlusSign,
    lang,
  });
  return (
    <Typography textAlign={textAlign} {...props}>
      {prefix}
      {formattedValue}
      {suffix}
    </Typography>
  );
};

export const MoneyValueV2 = ({
  currency = 'CAD',
  value,
  prefix,
  suffix,
  decimalPlaces = 2,
  showPlusSign,
  ...props
}: MoneyValueProps): ReactElement => {
  const { lang } = useTranslation();
  const formattedValue = formatCurrency({
    currency,
    value,
    decimalPlaces,
    showPlusSign,
    lang,
  });
  return (
    <Typography {...props}>
      {prefix}
      {formattedValue}
      {suffix}
    </Typography>
  );
};

// Used primarily for Financial Snapshot in rows that display time series
// accounting-style numbers (e.g. parentheses for negatives, red colour).
export const FinancialSnapshotMoneyValue = ({
  currency = 'CAD',
  value,
  originalValue,
  prefix,
  suffix,
  textAlign = 'right',
  allocation,
  ...props
}: MoneyValueProps): ReactElement => {
  const { t } = useTranslation('financialSnapshot');
  const val = value !== undefined && value !== null ? Math.round(value) : null;
  const isNeg = val !== null && val < 0;
  return val ? (
    <Stack>
      <MoneyValue
        sx={
          {
            textAlign: { textAlign },
            ...(props.sx ?? {}),
            ...(isNeg ? { color: 'error.main' } : {}),
          } as SxProps<Theme>
        }
        currency={currency}
        value={val !== null ? Math.abs(val) : null}
        variant={props.variant ?? 'smallText'}
        decimalPlaces={0}
        prefix={(prefix ?? '') + (isNeg ? '(' : '')}
        suffix={(isNeg ? ')' : '') + (suffix ?? '')}
        {...omit(props, ['sx', 'variant', 'textAlign'])}
      />
      {allocation && value !== originalValue && (
        <Typography
          variant="smallText"
          sx={{ textAlign: 'right' }}
          color="warning"
        >
          {isNil(allocation.amount)
            ? t('adjPercent', { percent: allocation.percent })
            : t('adj')}
        </Typography>
      )}
    </Stack>
  ) : (
    <Typography
      sx={{
        textAlign: 'right',
        ...(props.sx ?? {}),
      }}
      variant={props.variant ?? 'smallText'}
      {...omit(props, ['sx', 'variant'])}
    >
      -
    </Typography>
  );
};
