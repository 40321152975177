import { type ReactNode, forwardRef } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Select, { type SelectProps } from '@mui/material/Select';

export type SelectV3Props = SelectProps & {
  label?: string;
  hasValue?: boolean;
  valueText?: ReactNode;
};

export const SelectV3 = forwardRef<HTMLDivElement, SelectV3Props>(
  function SelectV3(selectProps, ref) {
    const { children, label, hasValue, valueText, ...props } = selectProps;

    return (
      <Select
        {...props}
        size={'small'}
        displayEmpty
        renderValue={() => {
          return (
            <>
              <span>{`${label}${valueText ? ': ' : ''}`}</span>
              {valueText && (
                <span suppressHydrationWarning css={{ fontWeight: 400 }}>
                  {valueText}
                </span>
              )}
            </>
          );
        }}
        IconComponent={
          selectProps.value || hasValue
            ? (props) => (
                <IconButton
                  {...props}
                  size="small"
                  sx={{
                    '&.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.MuiSelect-icon.MuiSelect-icon':
                      {
                        p: 0.25,
                        top: '8px',
                        right: '4px',
                        pointerEvents: 'unset',
                      },
                  }}
                  onClick={() => {
                    selectProps.onChange?.(
                      {
                        target: {
                          value: null,
                        },
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      } as any,
                      null
                    );
                  }}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              )
            : undefined
        }
        inputProps={{
          inputRef: ref,
        }}
      >
        {children}
      </Select>
    );
  }
);
