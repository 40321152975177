// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"676fbc5e6077d47bba42b1c1d00937ef911281f2"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/customer-portal";import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://bfb9ba9ce2714e2ebc6f4a9a355edc5f@o4505120768327680.ingest.sentry.io/4505121200013312',
  tracesSampleRate: 0.01,
  replaysOnErrorSampleRate: 0,
  debug: false,
});
