import { type ReactElement, type ReactNode } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';
import getConfig from 'next/config';
import Image from 'next/image';

import { LanguageSelector } from '../LanguageSelector';
import { type IPage } from '../TopBar';
import { TopBarItem } from '../TopBarItem';

interface IProps {
  pages: IPage[];
  additionalItems?: ReactNode;
  onNavItemClick: () => void;
  noLink?: boolean;
  linkProps?: {
    href?: string;
    rel?: string;
    target?: string;
  };
  logoSrc?: string;
  showLanguageSelector?: boolean;
}

export const TopBarDesktop = ({
  pages,
  additionalItems,
  onNavItemClick,
  noLink,
  linkProps,
  logoSrc,
  showLanguageSelector = false,
}: IProps): ReactElement => {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const { publicRuntimeConfig } = getConfig();

  const logo = (
    <Image
      priority
      alt={t('appTitle')}
      src={
        logoSrc ?? `${publicRuntimeConfig.public_prefix}/images/main-logo-2.svg`
      }
      height={32}
      width={150}
      className="logo"
      css={{
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'inline-block',
        },
      }}
    />
  );

  const logoWithLink = (
    <Link
      {...linkProps}
      href={linkProps?.href ? linkProps?.href : '/'}
      underline="none"
    >
      {logo}
    </Link>
  );

  const resolvedLogo = noLink ? logo : logoWithLink;

  return (
    <>
      {resolvedLogo}
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          overflowX: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          {pages?.map((page) => (
            <TopBarItem
              key={page.name}
              page={page.name}
              href={page.href}
              onClick={onNavItemClick}
              match={page.match}
            />
          ))}
          {additionalItems}
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        {showLanguageSelector && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <LanguageSelector />
          </Box>
        )}
      </Box>
    </>
  );
};
