import {
  type MutableRefObject,
  forwardRef,
  useCallback,
  useEffect,
} from 'react';

import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { ErrorCode, useDropzone } from 'react-dropzone';

import { useNotification } from '../ErrorContext';
import { AWS_MAX_FILE_SIZE } from '../utils/constants';
import DocumentUploadBox from './DocumentUploadBox';
import { type IDocumentUpload } from './types';

export const DocumentUploadDropzone = forwardRef<
  HTMLInputElement,
  IDocumentUpload
>(function DocumentUploadDropzone(
  { onFileSelected, maxFiles = 1, accept, sx, maxFileSize = AWS_MAX_FILE_SIZE },
  ref
) {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const { setErrorNotification } = useNotification();
  const onDrop = useCallback(
    async (acceptedFiles: Array<File>) => {
      if (acceptedFiles && acceptedFiles.length > 0 && onFileSelected) {
        await onFileSelected(acceptedFiles);
      }
    },
    [onFileSelected]
  );
  const { getRootProps, getInputProps, inputRef } = useDropzone({
    onDrop,
    onDropRejected(err) {
      if (
        err.some((errorItem) =>
          errorItem.errors.some((item) => item.code === ErrorCode.FileTooLarge)
        )
      ) {
        setErrorNotification(
          t('fileToLargeErrorMsg', {
            MAX_FILE_SIZE_LIMIT: AWS_MAX_FILE_SIZE / 1000000,
          })
        );
      } else {
        setErrorNotification(
          err?.[0].errors[0].message ?? t('errorInUploadingFile')
        );
      }
    },
    accept: accept ?? {
      'image/png': ['.png'],
      'image/jpg': ['.jpg', '.jpeg'],
      'image/heic': ['.heic'],
      'application/pdf': ['.pdf'],
      'application/json': ['.json'],
      'application/msword': ['.doc', '.docx'],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
      'text/csv': ['.csv'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
      'application/xml': ['.xml'],
    },
    maxFiles: maxFiles,
    maxSize: maxFileSize,
    multiple: maxFiles > 1,
  });

  useEffect(() => {
    if (ref && inputRef.current) {
      (ref as MutableRefObject<HTMLInputElement>).current = inputRef.current;
    }
  }, [inputRef, ref]);

  return (
    <DocumentUploadBox sx={sx}>
      <Box
        {...getRootProps()}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: theme.spacing(15),
          width: '100%',
          borderRadius: theme.spacing(1),
          border: `1px dashed #1212471F`,
          m: 0.25,
          cursor: 'pointer',
          boxSizing: 'content-box',
          ':hover': {
            backgroundColor: theme.palette.grey[200],
            border: `${theme.spacing(0.5)} dashed ${theme.palette.grey[400]}`,
            m: 0,
          },
        }}
      >
        <input {...getInputProps()} />
        <UploadFileOutlinedIcon
          data-test-id="documentUploadDropzone"
          sx={{
            fontSize: '40px',
            color: theme.palette.primary.main,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Trans
            i18nKey="common:dragAndDrop"
            components={{
              link: (
                <Link href="#" onClick={(e) => e.preventDefault()} mr={1} />
              ),
            }}
          />
        </Box>
      </Box>
    </DocumentUploadBox>
  );
});
