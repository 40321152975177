import { type ReactElement } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import useTranslation from 'next-translate/useTranslation';

import DocumentUploadBox from './DocumentUploadBox';
import MultiplesDocumentUploadFilenameBox from './MultiplesDocumentUploadFilenameBox';
import { type IMultiplesDocumentUpload, UploadState } from './types';

export const MultiplesDocumentUploadInProgress = ({
  selectedFiles,
  uploadState,
  onUploadFile,
  onFileRemoved,
  disabled,
  progress,
}: IMultiplesDocumentUpload): ReactElement => {
  const theme = useTheme();
  const { t } = useTranslation('common');
  return (
    <DocumentUploadBox>
      <Stack
        direction="column"
        rowGap={theme.spacing(1)}
        sx={{ width: '100%' }}
      >
        {selectedFiles.map((file) => (
          <MultiplesDocumentUploadFilenameBox
            key={file.id}
            fileId={file.id}
            fileName={file.fileName}
            uploadState={uploadState}
            onUploadFile={onUploadFile}
            onFileRemoved={onFileRemoved}
            progress={progress}
            disabled={disabled}
          />
        ))}
        {uploadState === UploadState.File_Selected && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={onUploadFile}
              disabled={disabled}
            >
              {t('uploadAll')}
            </Button>
          </Box>
        )}
      </Stack>
    </DocumentUploadBox>
  );
};
