import { type ChangeEvent, type ReactElement } from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import { type SxProps, type Theme, useTheme } from '@mui/material/styles';

interface IProps {
  value: File | null;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleRemove: () => void;
  buttonStyle?: SxProps<Theme> | undefined;
  imageStyle?: SxProps<Theme> | undefined;
}

export const ImagePreview = ({
  value,
  handleChange,
  handleRemove,
  buttonStyle,
  imageStyle,
}: IProps): ReactElement => {
  const theme = useTheme();
  return !value ? (
    <Button
      variant="outlined"
      color="primary"
      sx={buttonStyle}
      component="label"
    >
      <PhotoCamera />
      <input
        accept="image/png,.png,image/jpg,.jpg,.jpeg,image/heic,.heic,application/pdf,.pdf,application/json,.json,application/msword,.doc,.docx,application/vnd.ms-excel,.xls,.xlsx,text/csv,.csv,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.docx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xlsx,application/xml,.xml"
        type="file"
        id="select-image"
        css={{ display: 'none' }}
        onChange={handleChange}
      />
    </Button>
  ) : (
    <Card sx={imageStyle}>
      <IconButton
        sx={{ position: 'absolute', right: -8, top: 0 }}
        onClick={handleRemove}
      >
        <Avatar sx={{ bgcolor: theme.palette.error.main }}>
          <DeleteOutlineIcon />
        </Avatar>
      </IconButton>
      <CardMedia
        component="img"
        sx={{ objectFit: 'fill', height: '100%', width: '100%' }}
        image={URL.createObjectURL(value)}
      />
    </Card>
  );
};
