import { type ReactElement, forwardRef } from 'react';

import MuiAlert, { type AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { type ENotificationVariant } from '../ErrorContext';

const Alert = forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert ref={ref} {...props} />;
  }
);

export const Notification = ({
  notification,
  handleClose,
  message,
}: {
  notification: ENotificationVariant;
  handleClose: () => void;
  message: string;
}): ReactElement => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={6000}
      open={notification ? true : false}
      onClose={handleClose}
      sx={{
        mb: 7,
      }}
    >
      <Alert severity={notification ?? 'info'} onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};
