import { type ReactNode, useState } from 'react';

import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid2';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import useTranslation from 'next-translate/useTranslation';

import { NumericInput } from '../NumericInput';

export interface AmountRangeMenuProps {
  from: string;
  setFrom: (value: string) => void;
  to: string;
  setTo: (value: string) => void;
  onClose: () => void;
}

export const AmountRangeMenu = ({
  from,
  setFrom,
  to,
  setTo,
  onClose,
}: AmountRangeMenuProps): ReactNode => {
  const { t } = useTranslation('common');
  const [mode, setMode] = useState<'to' | 'from' | 'between'>(
    from && to ? 'between' : from ? 'from' : to ? 'to' : 'between'
  );
  const [fromValue, setFromValue] = useState<string>(from);
  const [toValue, setToValue] = useState<string>(to);
  const [error, setError] = useState<string | null>(null);
  const onUpdate = () => {
    switch (mode) {
      case 'to':
        setFrom('');
        setTo(toValue);
        break;
      case 'from':
        setFrom(fromValue);
        setTo('');
        break;
      case 'between':
        if (fromValue && toValue && Number(fromValue) > Number(toValue)) {
          setError(t('amountRangeError'));
          return;
        }
        setFrom(fromValue);
        setTo(toValue);
        break;
    }
    onClose();
  };
  return (
    <Grid container direction="column" gap={1} px={2}>
      <RadioGroup
        value={mode}
        onChange={(e) => {
          setError(null);
          setMode(e.target.value as typeof mode);
        }}
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <FormControlLabel value="from" control={<Radio />} label={t('from')} />
        <FormControlLabel value="to" control={<Radio />} label={t('to')} />
        <FormControlLabel
          value="between"
          control={<Radio />}
          label={t('between')}
        />
      </RadioGroup>
      <Grid container alignItems="center" gap={2}>
        {(mode === 'from' || mode === 'between') && (
          <NumericInput
            value={fromValue}
            onChange={(e) => {
              setError(null);
              setFromValue(e.target.value);
            }}
            variant="outlined"
            size="small"
          />
        )}
        {mode === 'between' && t('to')}
        {(mode === 'to' || mode === 'between') && (
          <NumericInput
            value={toValue}
            onChange={(e) => {
              setError(null);
              setToValue(e.target.value);
            }}
            variant="outlined"
            size="small"
          />
        )}
      </Grid>
      {error && (
        <Grid container sx={{ mt: -1 }}>
          <Typography
            variant="smallText"
            component="span"
            sx={{
              color: 'error.main',
            }}
          >
            {error}
          </Typography>
        </Grid>
      )}
      <Grid container justifyContent="flex-end">
        <Button onClick={onUpdate}>{t('update')}</Button>
      </Grid>
    </Grid>
  );
};
