import { type Theme } from '@mui/material/styles';
import getConfig from 'next/config';

import { createTheme3 } from '@credit-app/theme';

const getTheme = (host: string, locale: string): Theme => {
  let theme3 = createTheme3();
  const { publicRuntimeConfig } = getConfig();
  if (host?.includes('eqbank')) {
    theme3 = {
      ...theme3,
      palette: {
        ...theme3.palette,
        primary: {
          ...theme3.palette.primary,
          main: '#c33991',
        },
      },
      logo: `${publicRuntimeConfig.public_prefix}/images/logos/${locale ?? 'en'}/eq.svg`,
    };
  }
  if (host?.includes('autocapitalcanada')) {
    theme3 = {
      ...theme3,
      palette: {
        ...theme3.palette,
        primary: {
          ...theme3.palette.primary,
          main: '#d19434',
        },
      },
      logo: `${publicRuntimeConfig.public_prefix}/images/logos/${locale ?? 'en'}/acc.svg`,
      topBarLogoHeight: '40px',
    };
  }
  return theme3;
};

export default getTheme;
