export const shortenFileName = (
  fileName: string | null | undefined,
  length = 40
): string | undefined => {
  const lastIndex = fileName?.lastIndexOf('.');
  const name = fileName?.slice(0, lastIndex);
  const extension = fileName?.slice(lastIndex);
  const charactersToRemove = length - (extension?.length ?? 0) - 3;
  if (charactersToRemove > 0 && (name?.length ?? 0) > charactersToRemove) {
    const firstIndex = Math.floor(charactersToRemove / 2);
    const secondIndex = (name?.length ?? 0) - firstIndex;

    return `${name?.slice(0, firstIndex)}....${name?.slice(secondIndex)}${extension}`;
  }
  return `${fileName}`;
};
